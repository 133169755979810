import { COUNTRIES, TIMEZONES } from '@/constants/country';

export function getUserTimeZone() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (TIMEZONES[timezone] && TIMEZONES[timezone].c) {
    const zone = TIMEZONES[timezone].c[0];
    const country = COUNTRIES.find((c) => c.code === zone);
    if (country) return country;
  }

  return COUNTRIES[116]; // KOREA
}
