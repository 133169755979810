import type { Country } from '@/constants/country';
import type { AppFormControlProps } from '@components/TextField/FormControl.component';
import type { SxProps, Theme } from '@mui/material/styles';
import type { ControllerProps, FieldValue, FieldValues } from 'react-hook-form';

import React from 'react';

import { inputBaseClasses } from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller } from 'react-hook-form';

import { spreadMuiSxes } from '@/utils/styles';

import CountryCodeSelector from './CountryCodeSelector';

export interface InputWithCountryProps<
  Schema extends FieldValues,
  FieldName extends FieldValue<Schema>,
> extends Omit<AppFormControlProps, 'value' | 'name'> {
  countryOpt: Country;
  onSelectCountry: (opt: Country) => void;
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  control: ControllerProps<Schema, FieldName>['control'];
  name: ControllerProps<Schema, FieldName>['name'];
  //
  sx?: SxProps<Theme>;
}

type BaseInputWithCountryProps<
  Schema extends FieldValues,
  FieldName extends FieldValue<Schema>,
> = Omit<InputWithCountryProps<Schema, FieldName>, 'control' | 'name'> & {
  textFieldProps: TextFieldProps;
};

export function BaseInputWithCountry<
  Schema extends FieldValues,
  FieldName extends FieldValue<Schema>,
>({
  sx,
  label,
  placeholder,
  error,
  helperText,
  // NOTE: country control props
  countryOpt,
  onSelectCountry,
  textFieldProps,
}: BaseInputWithCountryProps<Schema, FieldName>) {
  return (
    <Stack gap="8px">
      {label}

      <Stack
        direction="row"
        width="100%"
        sx={[
          {
            [`.${inputBaseClasses.root}`]: {
              p: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              bgcolor: 'neutralV2.8',
              ':hover': {
                bgcolor: 'neutralV2.8',
              },
            },
            [`.${inputBaseClasses.input}`]: {
              height: 'unset',
              p: '9px 8px',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              typography: 'body2',
              bgcolor: 'inherit',
              ['&::placeholder']: {
                color: 'neutralV2.0',
              },
              ':hover': {
                boxShadow: (t) => `0 0 0 1px ${t.palette.neutralV2[25]}`,
              },
              ':focus': {
                boxShadow: (t) => `0 0 0 1px ${t.palette.neutralV2[2]}`,
              },
            },

            [`.${inputBaseClasses.error} .${inputBaseClasses.input}`]: {
              boxShadow: (t) => `0 0 0 1px ${t.palette.semantic.negative100}`,
            },
          },
          {
            border: '1px solid',
            borderColor: 'neutralV2.4',
            borderRadius: '8px',
          },
          ...spreadMuiSxes(sx),
        ]}>
        <CountryCodeSelector
          countryValue={countryOpt.code}
          onSelectCountry={onSelectCountry}
        />

        <TextField
          fullWidth
          error={Boolean(error)}
          inputProps={{
            maxLength: 15,
          }}
          // InputProps={{
          //   startAdornment: (
          //     <Typography
          //       variant="body2"
          //       color="text.2"
          //       px={0.5}
          //       flexShrink={0}>
          //       +{countryOpt.phone}
          //     </Typography>
          //   ),
          // }}
          placeholder={placeholder}
          {...textFieldProps}

          //
        />
      </Stack>
      {helperText && (
        <Typography variant="caption" color="semantic.negative100">
          {helperText}
        </Typography>
      )}
    </Stack>
  );
}

function InputWithCountry<
  Schema extends FieldValues,
  FieldName extends FieldValue<Schema>,
>({
  sx,
  // NOTE: Controller props
  name,
  control,
  label,
  // NOTE: Input props
  placeholder,
  error,
  helperText,
  // NOTE: country control props
  countryOpt,
  onSelectCountry,
}: InputWithCountryProps<Schema, FieldName>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = '', onChange, ...rest } }) => (
        <BaseInputWithCountry
          sx={sx}
          countryOpt={countryOpt}
          onSelectCountry={onSelectCountry}
          //
          label={label}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          textFieldProps={{
            ...rest,
            value,
            onChange: (e) => {
              const value = e.target.value;
              // NOTE: only digits or empty string
              if (value === '' || /^\d+$/.test(value)) {
                onChange(e);
              }
            },
          }}
        />
      )}
    />
  );
}

export default InputWithCountry;
