import React, { Fragment, useMemo, useState } from 'react';

import Image from 'next/image';

import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'next-i18next';

import AppButton, { CIGRO_BUTTON_CLASSNAME } from '@/components/Button';
import { AppDialogTitle } from '@/components/Dialog';
import Highlighter from '@/components/Highlighter';
import AppIcon from '@/components/Icon';
import AppSearchInput from '@/components/SearchInput';
import { COUNTRIES, Country } from '@/constants/country';
import { CIGRO_SCROLL_BAR_MIXINS } from '@/constants/mixins';

interface ICountryCodeSelector {
  countryValue: string;
  onSelectCountry: (option: Country) => void;
}
const CountryCodeSelector = ({
  countryValue,
  onSelectCountry,
}: ICountryCodeSelector) => {
  const { t } = useTranslation('common');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState('');

  const handleClosePopup = () => {
    setAnchorEl(null);
    setSearchValue('');
  };

  const filteredCountries = searchValue
    ? COUNTRIES.filter((country) => {
        const searchKeyword = searchValue.toLowerCase();
        if (country.code.toLowerCase().includes(searchKeyword)) return true;
        if (country.label.toLowerCase().includes(searchKeyword)) return true;
        if (searchKeyword.includes(country.phone)) return true;

        return false;
      })
    : COUNTRIES;

  return (
    <>
      <Stack
        onClick={(e) => setAnchorEl(e.currentTarget)}
        direction="row"
        sx={{
          gap: '10px',
          borderRadius: '8px 0px 0px 8px',
          bgcolor: 'neutralV2.7',
          cursor: 'pointer',
          padding: '0px 8px 0px 12px',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Image
          priority
          width={24}
          height={18}
          // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
          src={`https://flagcdn.com/w20/${countryValue.toLowerCase()}.png`}
          alt={countryValue}
          style={{ objectFit: 'contain' }}
          //
        />
        <AppIcon
          name={
            anchorEl
              ? 'ic_rounded_triangle_10_up'
              : 'ic_rounded_triangle_10_down'
          }
          size={10}
          color="neutralV2.1"
        />
      </Stack>

      {anchorEl && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={() => {
            handleClosePopup();
          }}
          slotProps={{
            root: {
              sx: {
                overflow: 'hidden',
              },
            },
            paper: {
              sx: {
                width: '328px',
                padding: '16px',
                position: 'relative',
              },
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <Stack gap="16px">
            <AppDialogTitle
              variant="h4"
              onClose={handleClosePopup}
              sx={{ p: 0 }}>
              {t('setting.country-code')}
            </AppDialogTitle>

            <AppSearchInput
              value={searchValue}
              onClear={() => {
                setSearchValue('');
              }}
              onDebouncedCallbackChange={setSearchValue}
              sx={{
                [`.${CIGRO_BUTTON_CLASSNAME}`]: {
                  display: 'none',
                },
              }}
              placeholder={t('navbar.search-placeholder')}
            />

            <Stack
              sx={{
                paddingRight: '8px',
                maxHeight: '240px',
                height: '240px',
                overflow: 'auto',
                ...CIGRO_SCROLL_BAR_MIXINS.sx,
              }}>
              {filteredCountries.map((option) => (
                <AppButton
                  onClick={() => {
                    if (countryValue !== option.code) {
                      onSelectCountry(option);
                    }

                    handleClosePopup();
                  }}
                  key={option.code}
                  variant="checkbox"
                  sx={{
                    marginTop: '4px',
                    gap: '12px',
                    alignItems: 'center',
                    justifyContent: 'start',
                    padding: '8px',
                    color: 'text.0',
                    ':disabled': {
                      bgcolor: 'neutralV2.6',
                      color: 'text.4',
                    },
                  }}>
                  <Image
                    width={24}
                    height={18}
                    loading="lazy"
                    // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt={option.label}
                    style={{ objectFit: 'contain' }}
                    //
                  />

                  <Highlighter
                    component="span"
                    variant="body2"
                    text={`${option.label} (+${option.phone})`}
                    highlight={searchValue}
                    sx={{
                      fontWeight: countryValue === option.code ? 600 : 400,
                    }}
                  />
                </AppButton>
              ))}
            </Stack>
          </Stack>
        </Popover>
      )}
    </>
  );
};

export default CountryCodeSelector;
