import { svgIconClasses } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

function UnstyledChevronDownIcon({
  className,
  color = '#B1B8C0',
  ...others
}: {
  className?: string;
  color?: string;
} & BoxProps) {
  return (
    <Box
      component="svg"
      className={svgIconClasses.root + ' ' + className}
      width={10}
      height={10}
      viewBox="0 0 10 10"
      {...others}>
      <path
        d="M5.81086 7.8764C5.41173 8.42946 4.58823 8.42946 4.18909 7.8764L1.81388 4.58521C1.33658 3.92384 1.80915 3 2.62476 3L7.3752 3C8.1908 3 8.66338 3.92384 8.18608 4.58521L5.81086 7.8764Z"
        fill={color}
      />
    </Box>
  );
}

const ChevronDownIcon = styled(UnstyledChevronDownIcon, {
  shouldForwardProp: () => true,
})(({ theme }) => ``);

export default ChevronDownIcon;
